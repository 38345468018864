.main-drawer {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 100;
  border-left: none;
  border-top: none;
  border-bottom: none;
  transition: width 0.2s;
}
.main-drawer.opened {
  width: 270px;
}
.main-drawer .logo-wrapper {
  border-left: none;
  border-top: none;
  border-right: none;
  height: 70px;
}

.logo {
  width: 30px;
  margin-bottom: 1px;
  animation: fadeIn 1s forwards;
}

.brand-name {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.drawer-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.drawer-menu a {
  width: 100%;
  display: flex;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: var(--rounded-btn, 0.5rem);
}
.drawer-menu a.active {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  color: #000;
}
.drawer-menu a:hover {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  color: #000;
}

.app[data-theme="dark"] .drawer-menu a {
  color: currentColor;
}

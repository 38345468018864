.login-form-wrapper {
  position: relative;
  max-width: 450px;
}

.login-form-wrapper:before {
  top: -40px;
  content: "";
  right: -40px;
  width: 148px;
  height: 148px;
  position: absolute;
  background-image: url(../../assets/img/auth-illustration-top.png);
}

@media (max-width: 600px) {
  .login-form-wrapper:before {
    background-image: unset;
    right: 0;
  }
}

.custom-calendar {
  @apply p-2 shadow-md bg-base-100 rounded-md;
  z-index: 100000000;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));

  --tw-border-opacity: 1;
}

.custom-calendar .react-datepicker__triangle {
  display: none;
}

.custom-calendar .react-datepicker__header {
  @apply bg-base-100;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.custom-calendar .react-datepicker__navigation-icon {
  top: 14px;
}
.custom-calendar .react-datepicker__day--selected {
  @apply bg-primary rounded-full;
}
.custom-calendar .react-datepicker__day:hover {
  @apply rounded-full;
}

.app[data-theme="dark"] .custom-calendar .react-datepicker__header {
  @apply border-gray-600;
}
.app[data-theme="dark"] .custom-calendar {
  @apply border-gray-600;
}

.app[data-theme="dark"] .custom-calendar .react-datepicker__day-name,
.app[data-theme="dark"] .custom-calendar .react-datepicker__day,
.app[data-theme="dark"] .custom-calendar .react-datepicker__current-month,
.app[data-theme="dark"] .custom-calendar.react-datepicker__time-name {
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}
.app .custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: unset;
}

.calendar-btn {
  @apply font-medium;
  border-color: rgba(31, 41, 55, 0.2);
}

.calendar-btn:focus {
  outline: 2px solid hsla(var(--bc) / 0.2);
  outline-offset: 2px;
}
.app[data-theme="dark"] .calendar-btn {
  border-color: rgba(166, 173, 186, 0.2);
}

.navbar a.active {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  color: #000;
}

.app .menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus) {
  background-color: transparent;
  color: #000;
}

.app[data-theme="dark"]
  .menu
  :where(li:not(.menu-title):not(:empty))
  > :where(*:not(ul):focus) {
  color: currentColor;
}

.app[data-theme="dark"] .navbar a.active {
  color: currentColor;
}
